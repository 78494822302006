<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="add_pledge">
      <el-form :model="form" ref="form">
        <el-form-item
          label="用户钱包地址："
          :label-width="formLabelWidth"
          prop="walletURL"
          :rules="[
            { required: true, message: '请输入用户钱包地址', trigger: 'blur' },
          ]"
        >
          <el-input v-model="form.walletURL" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="用户质押CPLE数量："
          :label-width="formLabelWidth"
          prop="cpleCount"
          :rules="[
            {
              required: true,
              message: '请输入用户质押CPLE数量',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="form.cpleCount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="Txid："
          :label-width="formLabelWidth"
          prop="txid"
          :rules="[
            {
              required: true,
              message: '请输入Txid',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="form.txid" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div class="add_pledge_footer">
        <el-button type="primary" @click="addCPLEPledge('form')"
          >添 加</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "CPLE质押",
        },
        {
          name: "添加质押",
        },
      ], // 面包屑数据

      form: {
        walletURL: "",
        cpleCount: "",
        txid: "",
      },
      formLabelWidth: "180px",
    };
  },

  methods: {
    addCPLEPledge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt1 = {
            url: reqUrl.beforeInsertPledge,
            method: "POST",
            params: JSON.stringify({
              cpleAddress: this.form.walletURL,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                const opt = {
                  url: reqUrl.addCplePledge,
                  method: "POST",
                  params: JSON.stringify({
                    cple: this.form.cpleCount.trim().replace(/,/g, ""),
                    cpleAddress: this.form.walletURL,
                    pledgeExamine: localStorage.getItem("userName"),
                    txid: this.form.txid,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        message: "添加成功,请到cple质押列表中查看",
                        type: "success",
                      });
                      this.form.cpleCount = "";
                      this.form.walletURL = "";
                      this.form.txid = "";
                      hideLoading();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: "网络超时",
                    });
                    console.log(err, "err1");
                    hideLoading();
                  },
                };
                request(opt);
              } else if (res.data.code == 4001) {
                const h = this.$createElement;
                this.$confirm(
                  h("p", null, [
                    h("span", null, "该用户为 "),
                    h("i", { style: "color: teal" }, "正在质押中"),
                    h("p", null, `质押数量：${res.data.data}`),
                  ]),
                  "提示",
                  {
                    confirmButtonText: "确认覆盖？",
                    cancelButtonText: "取消",
                  }
                )
                  .then(() => {
                    const opt = {
                      url: reqUrl.addCplePledge,
                      method: "POST",
                      params: JSON.stringify({
                        cple: this.form.cpleCount.trim().replace(/,/g, ""),
                        cpleAddress: this.form.walletURL,
                        pledgeExamine: localStorage.getItem("userName"),
                        txid: this.form.txid,
                      }),
                      resFunc: (res) => {
                        console.log(res.data);
                        if (res.data.code == 0) {
                          this.$message({
                            message: "添加成功,请到cple质押列表中查看",
                            type: "success",
                          });
                          this.form.cpleCount = "";
                          this.form.walletURL = "";
                          this.form.txid = "";
                          hideLoading();
                        } else if (res.data.code != 0) {
                          this.$notify.error({
                            title: "错误",
                            message: res.data.message,
                          });
                          hideLoading();
                        }
                        hideLoading();
                      },
                      errFunc: (err) => {
                        this.$notify.error({
                          title: "错误",
                          message: "网络超时",
                        });
                        console.log(err, "err1");
                        hideLoading();
                      },
                    };
                    request(opt);
                  })
                  .catch(() => {
                    hideLoading();
                  });
              } else if (res.data.code == 4002) {
                const h = this.$createElement;
                this.$msgbox({
                  title: "提示",
                  message: h("p", null, [
                    h("span", null, "该用户为 "),
                    h("i", { style: "color: teal" }, "正在解押中"),
                    h("p", null, "请解押完成后再重新质押"),
                  ]),
                  confirmButtonText: "确定",
                });
              } else if (res.data.code == 999) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt1);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add_pledge {
  width: 90%;
  height: 300px;
  margin: 20px auto;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .el-input {
    width: 80%;
  }

  ::v-deep .el-form-item__label {
    font-weight: bold;
  }

  .add_pledge_footer {
    display: flex;
    justify-content: center;

    .el-button {
      width: 100px;
    }
  }
}
</style>
