var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('Breadcrumb',{attrs:{"Breadcrumb":_vm.BreadcrumbCon}}),_c('div',{staticClass:"add_pledge"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"用户钱包地址：","label-width":_vm.formLabelWidth,"prop":"walletURL","rules":[
          { required: true, message: '请输入用户钱包地址', trigger: 'blur' },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.walletURL),callback:function ($$v) {_vm.$set(_vm.form, "walletURL", $$v)},expression:"form.walletURL"}})],1),_c('el-form-item',{attrs:{"label":"用户质押CPLE数量：","label-width":_vm.formLabelWidth,"prop":"cpleCount","rules":[
          {
            required: true,
            message: '请输入用户质押CPLE数量',
            trigger: 'blur',
          },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.cpleCount),callback:function ($$v) {_vm.$set(_vm.form, "cpleCount", $$v)},expression:"form.cpleCount"}})],1),_c('el-form-item',{attrs:{"label":"Txid：","label-width":_vm.formLabelWidth,"prop":"txid","rules":[
          {
            required: true,
            message: '请输入Txid',
            trigger: 'blur',
          },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.txid),callback:function ($$v) {_vm.$set(_vm.form, "txid", $$v)},expression:"form.txid"}})],1)],1),_c('div',{staticClass:"add_pledge_footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addCPLEPledge('form')}}},[_vm._v("添 加")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }